import React, { useState } from "react"
import { useIdentityContext } from 'react-netlify-identity-gotrue'

const DeleteUser = ({ user, fetchUsers, setDeleteUser }) => {

    const identity = useIdentityContext()
    const [deleting, setDeleting] = useState(false)

    const deleteUser = async () => {
        setDeleting(true)
        try {
            identity.authorizedFetch('/.netlify/functions/delete-user', {
                method: 'POST',
                body: JSON.stringify({
                    user_id: user.id
                })
            })
            .finally(() => { 
                setDeleting(false)
                setDeleteUser(false)
                fetchUsers(identity)
            })
        } catch (e) {
            console.error(e)
        }        
    }

    return(
        <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                Delete user?
                            </h3>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">
                                    Are you sure you want to delete this user? They will be permanently removed from our servers forever. This action cannot be undone.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button onClick={deleteUser} type="button" class={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm ${deleting && 'opacity-50 cursor-not-allowed'}`}>
                            Delete
                        </button>
                        <button onClick={() => setDeleteUser(false)} type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ApproveUser = ({ user, fetchUsers, setApproveUser }) => {

    const identity = useIdentityContext()
    const [approving, setApproving] = useState(false)

    const approveUser = async () => {
        setApproving(true)
        try {
            identity.authorizedFetch('/.netlify/functions/approve-user', {
                method: 'POST',
                body: JSON.stringify({
                    user_id: user.id,
                    name: user.user_metadata.full_name,
                    email: user.email,
                    app_metadata: user.app_metadata
                })
            })
            .finally(() => { 
                setApproving(false)
                setApproveUser(false)
                fetchUsers(identity)
            })
        } catch (e) {
            console.error(e)
        }        
    }

    return(
        <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-400 sm:mx-0 sm:h-10 sm:w-10">
                            <svg class="h-6 w-6 text-yellow-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                Approve user?
                            </h3>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">
                                    Are you sure you want to approve this user? This will give them access to the member's content.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button onClick={approveUser} type="button" class={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green text-base font-medium text-white hover:bg-darkgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green sm:ml-3 sm:w-auto sm:text-sm ${approving && 'opacity-50 cursor-not-allowed'}`}>
                            Approve
                        </button>
                        <button onClick={() => setApproveUser(false)} type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )    
}

const UserItem = ({ user, fetchUsers }) => {

    const [deleteUser, setDeleteUser] = useState(false)
    const [approveUser, setApproveUser] = useState(false)

    return (
        <li>
            { deleteUser && <DeleteUser {...{user, fetchUsers, setDeleteUser}} /> }
            { approveUser && <ApproveUser {...{user, fetchUsers, setApproveUser}} /> }
            <div className="block">
                <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                            <div>
                                <p className="text-sm font-medium text-indigo-600 truncate">{user.user_metadata.full_name}</p>
                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                    <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                    </svg>                                    
                                    <span className="truncate">{user.email}</span>
                                </p>
                            </div>
                            <div className="hidden md:block">
                                <div>
                                    <p className="text-sm text-gray-900">
                                        Applied on
                                        <time className="ml-1">
                                            {new Intl.DateTimeFormat("en-GB", {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit"
                                            }).format(Date.parse(user.created_at))}                                            
                                        </time>
                                    </p>
                                    <p className="mt-2 flex items-center text-sm text-gray-500">
                                        <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                        </svg>
                                        Confirmed email address
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => setDeleteUser(true)} type="button" class="inline-flex items-center px-3 py-2 text-sm leading-4 font-medium text-red-500 focus:outline-none">
                            Delete
                        </button>                                 
                        <button onClick={() => setApproveUser(true)} type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green hover:bg-darkgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green">
                            Approve
                        </button>          
                    </div>
                </div>
            </div>
        </li>        
    )
}

export default UserItem