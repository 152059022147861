import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import {  PageContext } from '../utils/context'
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { navigate } from 'gatsby'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import UserItem from 'src/components/UserItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-light-svg-icons'

const Admin = ({ location }) => {

    const identity = useIdentityContext()
    const [users, setUsers] = useState()
  
    useEffect(() => {
        identity.user && !identity?.user?.app_metadata.roles.includes('admin') && navigate('/')
    }, [identity.user])

    async function fetchUsers(identity) {
        try {
            const response = await identity.authorizedFetch('/.netlify/functions/get-users', {
                method: 'POST',
            })
            const json = await response.json()
            setUsers(json.users)
        } catch (e) {
            console.error(e)
        }
    }    

    useEffect(() => {
        fetchUsers(identity)
    }, [identity])

    return (
        <PageContext.Provider
            value={{
                hasHero: false,
                currentPage: false,
                location
            }}
        >
            <Layout location={location}>
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                    <div className="flex gap-x-8">
                        <div className="w-full">
                            <h3 className="text-lg font-light mb-5">Manage users</h3>
                            {
                                !users && <Skeleton height={80} count={5} />
                            }
                            {
                                ( users && users.filter(user => { 
                                        return (!user.app_metadata.roles || user.app_metadata.roles.length === 0) && user.confirmed_at
                                }).length === 0 ) &&
                                    <div class="text-center">
                                        <FontAwesomeIcon 
                                            icon={faUser}
                                            className="mx-auto h-12 w-12 text-gray-400"
                                        />
                                        <h3 class="mt-2 text-sm font-medium text-gray-900">No users</h3>
                                        <p class="mt-1 text-sm text-gray-500">
                                            You're all caught up! There are no users to manage currently.
                                        </p>
                                    </div>                                 
                            }
                            {
                                users && <div className="bg-white shadow overflow-hidden sm:rounded-md">
                                    <ul className="divide-y divide-gray-200">
                                        { users?.filter(user => { 
                                            return (!user.app_metadata.roles || user.app_metadata.roles.length === 0) && user.confirmed_at
                                        })
                                        .map(user => {
                                            return (
                                                <UserItem {...{user, fetchUsers}} />
                                            )
                                        })}
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Layout>        
        </PageContext.Provider>
    )
}

export default Admin